.toggle-switch {
  position: relative;
  cursor: pointer;
}
.toggle-switch .label {
  display: inline-block;
  color: #727272;
  font-size: 0.8rem;
}
.toggle-switch:hover .label {
  color: #444;
}
.toggle-switch:hover .switch .button {
  background: #aa9cc4;
}
.toggle-switch .switch {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  width: 26px;
  height: 8px;
  background: #cdcdcd;
  box-shadow: inset 1px 1px 0px #0000001f;
  border-radius: 15px;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.switch-on.toggle-switch .switch {
  background: #735d9d;
}
.toggle-switch .switch .button {
  display: block;
  position: absolute;
  top: -4px;
  left: 0px;
  width: 14px;
  height: 14px;
  background: #aa9cc4;
  border-radius: 100%;
  box-shadow: 1px 1px 1px #0000008f;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.toggle-switch .switch .button .icon {
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 5px;
  height: 7px;
  border-right: 2px solid #735d9d;
  border-bottom: 2px solid #735d9d;
  opacity: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.switch-on.toggle-switch .switch .button {
  left: 12px;
  background-color: #fff;
}
.switch-on.toggle-switch .switch .button .icon {
  opacity: 1;
}
.graph-wrapper {
  position: relative;
  height: 800px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.graph-wrapper.maximized {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: auto;
  box-shadow: 0 2px 7px #0000000f;
  border-radius: 3px;
  overflow: hidden;
  z-index: 1200;
}
.graph-wrapper .graph-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.graph-wrapper .no-results-overlay {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #e9e9e9bd;
  font-size: 1.2rem;
  font-weight: bold;
  color: #3c3c3c;
  z-index: 10;
  pointer-events: none;
}
.graph-wrapper .controls {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
}
.graph-wrapper .graph-legend {
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 10px;
  background-color: #ffffffa8;
  border-radius: 3px;
}
.graph-wrapper .graph-legend .legend-item {
  cursor: pointer;
}
.graph-wrapper .graph-legend .legend-item .legend-color {
  display: inline-block;
  position: relative;
  top: -4px;
  width: 18px;
  height: 3px;
  margin-right: 5px;
  border-radius: 2px;
}
.graph-wrapper .graph-legend .legend-item .legend-label {
  font-size: 0.8rem;
}
.graph-wrapper .full-view-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.graph-wrapper .full-view-button button,
.graph-wrapper .controls button,
.graph-wrapper .full-view-button a,
.graph-wrapper .controls a {
  padding: 3px 8px;
  background-color: #f3f3f3;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  box-shadow: 0 2px 2px #00000008;
  font-size: 0.8rem;
  cursor: pointer;
}
.graph-wrapper svg text {
  pointer-events: none;
}
.graph-wrapper svg g circle.clickable {
  cursor: pointer;
}
.hover-info {
  position: fixed;
  top: 0;
  max-width: 500px;
  min-width: 300px;
  max-height: 80px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 9px #0000003d;
  transform: translateY(-100%);
  z-index: 500;
  pointer-events: none;
  overflow: hidden;
  -webkit-transition: max-height 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out;
  transition: max-height 300ms ease-in-out;
  /*
	&:not(.no-truncate) &:after {
		content: " ";

		display: block;

		position: absolute;

		bottom: 0;
		left: 0;
		right: 0;

		height: 40px;

		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
}
.hover-info.max-height {
  max-height: 1000px;
}
.hover-info.max-height:after {
  display: none;
}
.hover-info .highlight {
  background: rgba(115, 93, 157, 0.3);
  outline: 2px solid rgba(115, 93, 157, 0.3);
  border-radius: 3px;
}
.note-style {
  font-style: italic;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  text-indent: 0;
  line-height: 1.3rem;
  font-size: 1.15rem;
}
.html-content {
  margin-bottom: 6rem;
}
.html-content.font-junicode {
  font-family: 'Junicode';
  font-feature-settings: "ss01";
  font-style: normal;
  font-size: 1.3rem;
}
.html-content .formali {
  max-width: 750px;
  margin: 0 auto;
}
.html-content p {
  margin-bottom: 0;
}
.html-content p.no-indent {
  text-indent: 0;
}
.html-content blockquote {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.html-content h1,
.html-content h2,
.html-content h3,
.html-content h4,
.html-content h5 {
  font-family: 'Roboto', sans-serif;
}
.html-content h2 {
  margin: 2rem 0 1rem 0;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}
.html-content h3 {
  margin: 1rem 0 1rem 0;
  text-align: center;
  font-size: 1.2rem;
}
.html-content .caption {
  font-style: italic;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  text-indent: 0;
  line-height: 1.3rem;
  font-size: 1.15rem;
  text-align: center;
}
.html-content .margin {
  display: block;
  float: left;
  width: 100px;
  margin-left: -120px;
  font-style: italic;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  text-indent: 0;
  line-height: 1.3rem;
  font-size: 1.15rem;
}
.html-content .footnote {
  font-style: italic;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  text-indent: 0;
  line-height: 1.3rem;
  font-size: 1.15rem;
  color: #626262;
}
.html-content .footnote .note-content {
  display: block;
  position: relative;
  float: right;
  width: 100px;
  margin-right: -120px;
}
.html-content .footnote .note-content:before {
  content: "*";
  display: inline-block;
  position: absolute;
  left: -1rem;
}
.html-content .footnote:before {
  content: "*";
}
.html-content .smallcap {
  font-variant: all-small-caps;
}
.html-content sup {
  top: 0;
  vertical-align: super;
  line-height: 0;
}
.html-content sub {
  vertical-align: subscript;
  line-height: 0;
}
.html-content .mod {
  display: inline-block;
  transform: translateX(50%);
}
.html-content .mod:before {
  margin-left: -40%;
}
.html-content .ring-below,
.html-content .macron,
.html-content .circumflex-below,
.html-content .acute,
.html-content .breve {
  display: inline-block;
  position: relative;
  text-indent: 0;
}
.html-content .ring-below:before,
.html-content .macron:before,
.html-content .circumflex-below:before,
.html-content .acute:before,
.html-content .breve:before {
  position: absolute;
  text-indent: 0;
}
.html-content .ring-below:before {
  content: " ̥";
  left: 50%;
  bottom: -0.05rem;
}
.html-content .macron:before {
  content: "ˉ";
}
.html-content .circumflex-below:before {
  content: " ̭";
  left: 50%;
  bottom: -0.05rem;
}
.html-content .acute:before {
  content: "ˊ";
  top: -3px;
  left: calc(50%);
}
.html-content .breve:before {
  content: " ̆";
  top: -0.2rem;
  left: 100%;
}
.tabs-wrapper {
  margin-top: 20px;
}
.tabs-wrapper > .card {
  border-radius: 0;
  border: none;
  background: #f9f9f9;
}
.tabs-wrapper > .card .card-body {
  /*    min-height: 250px;*/
}
.tabs-wrapper .tabs-wrapper .nav-tabs .nav-item {
  background: #f9f9f9;
}
.tabs-wrapper .tabs-wrapper .nav-tabs .nav-link.active {
  background: #fff;
}
.tabs-wrapper .tabs-wrapper .card {
  background: #fff;
}
.tabs-wrapper .nav-tabs {
  display: flex;
  border-bottom: 0;
}
.tabs-wrapper .nav-tabs .nav-link {
  display: block;
  padding: 10px 15px 10px 15px;
  border: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top: transparent 2px solid;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 15px center;
  -webkit-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.entry-container .tabs-wrapper .nav-tabs .nav-link {
  padding: 10px 50px 10px 15px;
}
.tabs-wrapper .nav-tabs .tab-faersla .nav-link {
  background-image: url('../img/text.png');
}
.tabs-wrapper .nav-tabs .tab-net .nav-link {
  background-image: url('../img/network.png');
}
.tabs-wrapper .nav-tabs .nav-link:hover:not(.active) {
  background-color: #f9f9f9;
}
.tabs-wrapper .nav-tabs .nav-link.active {
  border-top-color: #55707d;
  background-color: #f9f9f9;
}
@media screen and (max-width: 550px) {
  .tabs-wrapper .nav-tabs .nav-link {
    margin: 2px;
    border-radius: 0.25rem;
  }
}
@media screen and (max-width: 550px) {
  .tabs-wrapper .nav-tabs {
    margin-bottom: 2px;
  }
}
.tabs-wrapper .tab {
  height: 0;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
}
.tabs-wrapper .tab .card {
  border-radius: 0 !important;
  border: none !important;
}
.tabs-wrapper .tab.selected {
  height: auto;
  min-height: 10px;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  overflow: auto;
}
.loading-bar {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  height: 0px;
  background: #fff6;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.loading .loading-bar {
  height: 4px;
}
.loading-bar .indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #735d9d;
  animation-name: loading-bar-animate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
@keyframes loading-bar-animate {
  0% {
    left: 0;
    right: auto;
    width: 0%;
  }
  49% {
    left: 0;
    right: auto;
    width: 100%;
  }
  50% {
    left: auto;
    right: 0;
    width: 100%;
  }
  100% {
    left: auto;
    right: 0;
    width: 0%;
  }
}
