@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: Junicode;
	/*src: url(fonts/Junicode.woff);*/
	src: url(fonts/Junicode2.004-Light.woff2);
	font-display: swap;
}
@font-face {
	font-family: Junicode;
	/*src: url(fonts/Junicode-Italic.woff);*/
	src: url(fonts/Junicode2.004-LightItalic.woff2);
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: Junicode;
	/*src: url(fonts/Junicode-Bold.woff);*/
	src: url(fonts/Junicode2.004-Smbold.woff2);
	font-weight: bold;
	font-display: swap;
}
@font-face {
	font-family: Junicode;
	/*src: url(fonts/Junicode-BoldItalic.woff);*/
	src: url(fonts/Junicode-BoldItalic.woff);
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,600;1,400;1,600&display=swap');

@import url('css/components.css');

@layer base {
	h1 {
		@apply text-3xl;
	}

	h2 {
		@apply text-xl;
	}

	input {
		@apply p-2 block w-full rounded border border-gray shadow focus:shadow-gray outline-1 outline-arnastofnun;
	}

	.btn-base {
		@apply rounded px-4 py-2 border border-gray shadow hover:bg-blue-light;
	}

	.btn {
		@apply btn-base text-sm font-bold transition-all;
	}

	.btn-small {
		@apply btn;
		@apply px-2 py-1 text-xs font-normal;
	}

	.btn-small.disabled {
		@apply border-gray-light;
		@apply shadow-none;
	}

	.btn-small.no-hover {
		@apply hover:bg-white;
	}

	.lnk {
		@apply text-sm underline decoration-1 decoration-gray underline-offset-4 decoration-dotted hover:underline;
	}

	hr {
		@apply border-gray;
	}

	button.btn-red {
		@apply rounded px-4 py-2 bg-arnastofnun text-sm font-bold text-white shadow;
	}

	.card {
		@apply rounded bg-gray-light;
	}

	.card .card-body {
		@apply mb-6 p-6;
	}

	.card.card-narrow {
		@apply p-4;
	}

	.card .btn-small {
		@apply inline-block mb-2;
	}

	.card h4 {
		@apply text-lg text-blue;
	}

	span[title] {
		@apply underline decoration-1 decoration-dotted text-blue;
	}

	.html-content h1, .html-content p {
		@apply mb-4;
	}

	.html-content a {
		@apply underline text-blue;
	}

	.entry-text a {
		@apply underline decoration-dotted underline-offset-4 decoration-1 text-blue;
	}

	.menu-links a {
		@apply transition-[border] duration-300 border-l-4 border-white/0;
	}

	.menu-links a:hover {
		@apply border-l-4 border-white/50;
	}

	.menu-links a.active {
		@apply border-white font-bold;
	}

	.html-content p img {
		@apply w-full xl:w-1/2 md:float-right my-4 xl:mt-0 ml-0 xl:ml-4 rounded;
	}
}

html, body, label {
	font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Roboto Condensed', sans-serif;
}


@font-face {
	font-family: 'Andron Scriptor Web';
	src: url('fonts/AndronScriptorWeb.eot') format('embedded-opentype');
	src: url('fonts/AndronScriptorWeb.eot?#iefix') format('embedded-opentype'),
		url('fonts/AndronScriptorWeb.svg') format('svg'),
		url('fonts/AndronScriptorWeb.woff') format('woff'),
		url('fonts/AndronScriptorWeb.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.header {
	background-image: url(img/header-deco.png);
	background-repeat: no-repeat;
	background-position: top center;

	border-bottom: 1px solid #ffffff99;
}

@font-face {
	font-family: 'Italica Vetus';
	src: url('fonts/italica_sn_1-201.otf');

/*
	src: url('fonts/AndronScriptorWeb.eot');
	src: url('fonts/AndronScriptorWeb.eot?#iefix') format('embedded-opentype'),
		url('fonts/AndronScriptorWeb.woff') format('woff'),
		url('fonts/AndronScriptorWeb.ttf') format('truetype');
*/
	font-weight: normal;
	font-style: normal;

}

.font-junicode {
	font-family: 'Junicode';
	font-style: normal;
	font-feature-settings: "ss01";
	/*font-variant: no-common-ligatures;*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.font-italica {
	font-family: 'Italica Vetus';
    border: none;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-top: transparent 2px solid;
	font-style: normal;
}

.font-andron {
	font-family: 'Andron Scriptor Web';
    border: none;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-top: transparent 2px solid;
	font-style: normal;
}
